import { createTheme } from '@mui/material'
import {
  TopResumeTheme,
  colors as trColors,
} from '../../../topresume.com/src/styles'
import merge from 'lodash/merge'

export const TopCVTheme = createTheme(
  merge({}, TopResumeTheme, {
    themeName: 'TopCV',
    trColors,
  })
)

export default TopCVTheme
